<template>
  <div v-editable="blok" :class="marginClasses">
    <Intersect :threshold="0.5" @enter="intersectPromotionView">
      <HorizontalItemsScroller
        loop
        autoplay
        :autoplay-speed="7000"
        :show-buttons="false"
        :dots="true"
        dots-style="bar"
        :has-gradient="false"
      >
        <div
          v-for="(slide, index) in blok.slides"
          :key="slide._uid"
          class="scroller-slide w-full"
        >
          <NuxtLink
            v-if="isSbSlide(slide)"
            :to="getSlideTo(slide)"
            :target="getSlideTarget(slide)"
            @mousedown="clickObserver"
          >
            <StoryblokComponent
              v-for="image in slide.image"
              :key="image._uid"
              :blok="image"
              :preload-media="index === 0 && preloadMedia"
              :preload="index === 0 && preloadMedia"
              :loading="index === 0 && preloadMedia ? 'eager' : 'lazy'"
            />
          </NuxtLink>
          <StoryblokComponent
            v-else
            :blok="slide"
            class="!mb-0"
            :preload-media="index === 0 && preloadMedia"
          />
        </div>
      </HorizontalItemsScroller>
    </Intersect>
  </div>
</template>

<script setup lang="ts">
import type { SbHero, SbSlide, SbSlideShow } from '~/storyblok/types/storyblok'
import { getLinkTarget, resolvSbLink } from '~/storyblok/utils/links'

const props = defineProps({
  blok: {
    type: Object as PropType<SbSlideShow>,
    required: true,
  },
  preloadMedia: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { $currentShop } = useNuxtApp()
const { trackPromotionView, trackPromotionClick } = await useTrackingEvents()

const isSbSlide = (entry: SbSlide | SbHero): entry is SbSlide => {
  return entry.component === 'Slide'
}

const getSlideTo = (input: SbSlide) => {
  const link = getLinkRef(input.cta_ref)
  return link?.cta_url ? resolvSbLink(link, $currentShop) : undefined
}
const getSlideTarget = (input: SbSlide) =>
  getLinkTarget(getLinkRef(input.cta_ref))

const { marginClasses } = useStoryblokMargins(props.blok)

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}

const clickObserver = () => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionClick(props.blok)
  }
}
</script>
